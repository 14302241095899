<template>
  <div class="header-box">
    <div><img src="../../assets/image/logo.png" alt=""/></div>
    <h1>
      <a href="javaScript:"></a>
    </h1>

    <div class="header-right">
      <!-- <span class="backold"
            ><a href="https://www.xmeye.net/index">{{ $t(`backOld`) }}</a></span
          > -->
      <!-- <SLanguage /> -->

      <span class="download" @click="showDownLoadCode"
            >{{ $t(`webUnitDownload`) }}
          </span>

      <a-dropdown placement="bottomCenter">
        
        <!-- <div><span class="download" @click="showDownLoadCode"
            >{{ $t(`webUnitDownload`) }}
          </span></div> -->
        
        <a-button type="link" class="app">{{ $t(`appDownload`) }}</a-button>
        <a-menu slot="overlay" style="margin-top: 1.5vh">
          <!-- <a-menu-item class="downloadcode">
            <img class="img" src="../../assets/image/icon_app.png" alt="" />
            <p class="font">{{ $t(`officialEdition`) }}</p>
          </a-menu-item> -->
          <a-menu-item class="downloadcode">
            <img class="img" src="../../assets/image/xmeyepro.png" alt="" />
            <!-- <p class="font">{{ $t(`proEdition`) }}</p> -->
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <span class="callus" @click="showDrawer">
        {{ $t(`callUs`) }}
        <a-drawer
          :title="$t('supportTitle')"
          placement="bottom"
          :closable="false"
          :visible="visible"
          @close="onClose"
          height="30vh"
        >
          <p>{{ $t(`supportPhone`) }}：4008553199</p>
          <p>{{ $t(`supportMail`) }}：nbuhws@gmail.com</p>
          <p>{{ $t(`supportTime`) }}</p>
        </a-drawer>
      </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <SLanguage />
    </div>
  </div>
</template>

<script>
import SLanguage from "../../components/SelectLanguage.vue";

export default {
  name: "",
  components: { SLanguage },
  props: {},
  data() {
    return {
      visible: false
    };
  },
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {
    //显示面板
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    showDownLoadCode(){
      window.open("/download/XmeyeVideoPlayToolSetup.exe");
    }
    
  }
};
</script>

<style lang="less" scoped>
.header-box {
  width: 100%;
  background-color: #fff;
  padding: 0 50px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}
.header-right {
  float: right;
  width: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  /* top: -25% \9 ; */
}

.ant-select-selection-selected-value {
  margin-left: 26px;
  float: none;
}
.img {
  display: block;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}
.font {
  text-align: center;
  margin: 10px 0;
}
.backold,
.download,
.callus,
.app {
  font-size: 16px;
}
.backold {
  display: inline-block;
  cursor: pointer;
  margin-right: 1.7vw;
}
.backold > a {
  color: #000;
}
.download {
  cursor: pointer;
  color: #000;
  margin-left: 20px;
}
.download:hover,
.app:hover,
.callus:hover {
  color: #000;
}
.app {
  color: #000;
  padding: 0 20px;
}
.callus {
  cursor: pointer;
  color: #000;
}
</style>
