<template>
  <div class="languageBox" ref="country">
    <img v-show="imgShow" class="defimg" :src="defaultsrc" alt="" />
    <a-select
      :default-value="defaultLanuage"
      style="width: 160px"
      @change="handleChange"
    >
      <a-select-option
        v-for="(item, index) in language"
        :key="index"
        :value="item.value"
        dropdownMenuStyle="{z-index:999999}"
      >
        <img :src="item.src" class="national" />{{ item.text }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  inject: ["changelan"],
  name: "SLanguage",
  data() {
    return {
      defaultLanuage: "简体中文",
      defaultvalue: "zh",
      defaultsrc: require("../assets/image/national/zh.png"),
      boxWidth: true,
      imgShow: true,
      first: true,
      language: [
        {
          value: "zh",
          text: "简体中文",
          src: require("../assets/image/national/zh.png")
        },
        {
          value: "en",
          text: "English",
          src: require("../assets/image/national/en.png")
        },
        {
          value: "zh_TW",
          text: "繁体中文",
          src: require("../assets/image/national/zh_HANT.png")
        },
        {
          value: "ru_CN",
          text: "русский язык",
          src: require("../assets/image/national/ru.png")
        },
        {
          value: "de_US",
          text: "Deutsch",
          src: require("../assets/image/national/de.png")
        },
        {
          value: "ko_KR",
          text: "한국어",
          src: require("../assets/image/national/ko.png")
        },
        {
          value: "it_US",
          text: "Italiano",
          src: require("../assets/image/national/it.png")
        },
        {
          value: "fr_US",
          text: "Français",
          src: require("../assets/image/national/fr.png")
        },
        {
          value: "es_US",
          text: "Español",
          src: require("../assets/image/national/es.png")
        },
        {
          value: "pt",
          text: "Portugués",
          src: require("../assets/image/national/pt.png")
        },
        {
          value: "cs_US",
          text: "Česko",
          src: require("../assets/image/national/cs.png")
        },
        {
          value: "ja",
          text: "日本語",
          src: require("../assets/image/national/ja.png")
        },
        {
          value: "pt_BR",
          text: "Português",
          src: require("../assets/image/national/pt_BR.png")
        }
      ]
    };
  },
  created() {
    this.defaultvalue = sessionStorage.getItem("languagevalue")
      ? sessionStorage.getItem("languagevalue")
      : this.defaultvalue;

    this.defaultsrc = sessionStorage.getItem("languagesrc")
      ? sessionStorage.getItem("languagesrc")
      : this.defaultsrc;

    this.$i18n.locale = this.defaultvalue;
    this.defaultLanuage = sessionStorage.getItem("languagetext")
      ? sessionStorage.getItem("languagetext")
      : this.defaultLanuage;
      // console.log(this.first,'this.first')
      
    this.first = sessionStorage.getItem("first")
      ? sessionStorage.getItem("languagetext")
      : this.first;
      // console.log(this.first,'this.first')
    if (this.first == true) {
      this.test(this.getBrowserLanguage());
    }
  },
  methods: {
    getBrowserLanguage() {
      let lan = navigator.language;
      if (lan == "zh-CN") {
        return "zh";
      } else if (lan == "de") {
        return "de_US";
      } else if (lan == "en") {
        return "en";
      } else if (lan == "fr") {
        return "fr_US";
      } else if (lan == "zh-TW") {
        return "zh_TW";
      } else if (lan == "ko") {
        return "ko_KR";
      } else if (lan == "ja") {
        return "ja";
      } else if (lan == "it") {
        return "it_US";
      } else if (lan == "pt-BR") {
        return "pt_BR";
      } else if (lan == "pt-PT") {
        return "pt";
      } else if (lan == "es") {
        return "es_US";
      } else if (lan == "cs") {
        return "cs_US";
      } else if (lan == "ru") {
        return "ru_CN";
      } else {
        return "en";
      }
    },

    test(lan) {
      sessionStorage.setItem("first", false);
      this.$i18n.locale = lan;
      sessionStorage.setItem("languagevalue", lan);
      this.language.map(item => {
        if (item.value == lan) {
          this.defaultLanuage = item.text;
          this.defaultvalue = item.value;
          this.defaultsrc = item.src;
          sessionStorage.setItem("languagetext", item.text);
          sessionStorage.setItem("languagesrc", item.src);
        }
      });
    },

    handleChange(value) {
      this.$store.commit("ChangeLan", value);
      this.$refs.country.children[1].children[0].children[0].style.marginLeft =
        "11px";
      this.$i18n.locale = value;
      sessionStorage.setItem("languagevalue", value);
      this.imgShow = false;
      this.language.map(item => {
        if (item.value == value) {
          sessionStorage.setItem("languagetext", item.text);
          sessionStorage.setItem("languagesrc", item.src);
        }
      });
      this.changelan();
    }
  }
};
</script>

<style scoped>
.languageBox {
  display: inline-block;
  position: relative;
  /* font-size: 16px; */
}
.national {
  display: inline-block;
  width: 21px;
  height: 14px;
  margin-right: 4px;
  line-height: 30px;
  margin-bottom: 4px;
}
.defimg {
  position: absolute;
  left: 10px;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
}
.languageBox >>> .ant-select{
  font-size: 16px;
}
.languageBox >>> .ant-select-selection__rendered {
  margin-left: 34px;
}
.languageBox >>> .ant-select-selection {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.9);
}
.languageBox >>> .ant-select-dropdown ant-select-dropdown--single {
  scrollbar-width: thin;
}
</style>
