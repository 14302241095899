<template>
  <div class="bottom">
    <!-- <span class="copy">© 杭州杰峰科技有限公司2022 版权所有</span>
    <a target="_blank" href="https://beian.miit.gov.cn"
      >浙ICP备2022006639号-6</a
    > -->
    <span class="copy">© 杭州雄迈信息技术有限公司2022 版权所有</span>
    <a target="_blank" href="https://beian.miit.gov.cn"
      >浙ICP备10052164号-3</a
    >
    <!-- <a
            target="_blank"
            href="http://idinfo.zjamr.zj.gov.cn/bscx.do?method=lzxx&id=3301833301080000026631"
            ><img class="license" src="../../assets/image/i_lo2.gif" alt=""
          /></a>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33018302001246"
          >
            <img src="../../assets/image/ipc-icon.png" alt="" />
            浙公网安备33018302001246号
          </a> -->
    <div id="footer"></div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  destroyed() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.bottom {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy {
  color: #666;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
.bottom a {
  margin-left: 3px;
  color: #666;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
</style>
